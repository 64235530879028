import React, { Component } from "react";
import compkit from './Style/compkit.jpg';
import bg from './Style/1.png'
import './Style/about.css';
import store from "../stores/ShopStore";

export default class About extends Component {
    componentDidMount = () => {
        store.setSelectedPageIndex(2);
    }

    render() {
        return (
            <div className="about">
                <div className="about_wrap">
                    <div className="about_title">
                        <h1 className="about_title-title">Наш магазин предлагает широкий ассортимент товаров</h1>
                        <p className="about_title-desc">более подробная информация в разделе Каталог</p>
                    </div>
                    <div className="about_list">
                        <div className="about_item-left">
                            <img className="about_item-img" src={compkit}></img>
                            <div className="about_desc">
                                <h3 className="about_desc-title">О нас</h3>
                                <p className="about_desc-desc">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            </div>
                        </div>
                        <div className="about_item-right">
                        <img className="about_item-img" src={compkit}></img>
                            <div className="about_desc">
                                <h3 className="about_desc-title">Комплектующие для компьютеров</h3>
                                <p className="about_desc-desc">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            </div>
                        </div>
                        <div className="about_item-left">
                        <img className="about_item-img" src={compkit}></img>
                            <div className="about_desc">
                                <h3 className="about_desc-title">Системные блоки</h3>
                                <p className="about_desc-desc">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            </div>
                        </div>
                        <div className="about_item-right">
                        <img className="about_item-img" src={compkit}></img>
                            <div className="about_desc">
                                <h3 className="about_desc-title">Сетевое оборудование</h3>
                                <p className="about_desc-desc">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        );
    }
}
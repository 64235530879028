import { React, Component } from 'react';
import { HashLink } from 'react-router-hash-link';
import phone from './components/Style/phone-call.png';
import email from './components/Style/envelope.png';
import map from './components/Style/location.png';
import logo from './components/Style/logo.svg';
import './footer.css';

class Footer extends Component {
    render() {
        return (
            <div className='footer'>
                <div className='footer_wrap'>
                    <div className='footer_logo'>
                        <HashLink smooth to="/">
                            <img className='footer_logo-item' src={logo} style={{ color: '#ffffffff' }}></img>
                        </HashLink>
                    </div>
                    <ul className='footer_address-list'>
                        <HashLink className='footer_address-item' smooth to='https://yandex.ru/maps/10735/krasnogorsk/?ll=37.330397%2C55.831001&z=13' style={{ textDecoration: 'none'}}>
                            <img className='footer_address-img' src={map}></img>
                            <div className='footer_address-desc'>г. Москва, ул. Есенина, д. Каруселина</div>
                        </HashLink>
                        <li className='footer_address-item' style={{ textDecoration: 'none'}}>
                            <img className='footer_address-img' src={phone}></img>
                            <a href='tel:+79162288888' className='footer_address-desc'>+7(916)228-88-88</a>
                        </li>
                        <li className='footer_address-item' style={{ textDecoration: 'none'}}>
                            <img className='footer_address-img' src={email}></img>
                            <div className='footer_address-desc'>loreipsum@loremipsum.com</div>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}
export default Footer;
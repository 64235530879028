import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Tabs from 'devextreme-react/tabs';
import store from "../stores/ShopStore";
import { observer } from 'mobx-react';
import './Style/menu.css'

const tabs = [
    { id: 0, text: "Главная", icon: "home", to: "/" },
    { id: 1, text: "Каталог", icon: "product", to: "/catalog" },
    { id: 2, text: "О нас", icon: "info", to: "/about" }
];

@observer
export default class Menu extends Component {

    componentDidMount = () => {
        this.setState({ selectedIndex: 1 });
    }

    onTabsSelectionChanged = (args) => {
        if (args.name === 'selectedIndex') {
            if (args.value < 0) return;

            store.setSelectedPageIndex(args.value);
        }
    }

    itemRender = (item) => {
        return (
            <div style={{display: "flex", flexDirection: "row"}}>
                <div className={'dx-icon-' + item.icon} style={{fontSize: "22px"}} />
                <div className='header-menu__item'>
                    <Link to={item.to}>  </Link>
                    {item.text}
                </div>
            </div>
        );
    }

    render() {
        return (
                <div className="header-menu">
                    <div className="dx-icon-home" style={{display: "flex", alignItems: "center", fontSize: "22px", color: "#476070"}}> <Link  to="/" className='header-menu__item' style={{marginLeft: "-15px"}}> Главная  </Link>   </div>
                    <div className="dx-icon-detailslayout" style={{display: "flex", alignItems: "center", fontSize: "22px", color: "#476070"}}> <Link  to="/catalog" className='header-menu__item' style={{marginLeft: "-15px"}}> Каталог  </Link>   </div>
                    <div className="dx-icon-info" style={{display: "flex", alignItems: "center", fontSize: "22px", color: "#476070"}}> <Link  to="/about" className='header-menu__item' style={{marginLeft: "-15px"}}> О нас  </Link>   </div>
                </div>
        );
    }
}